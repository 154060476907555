@import "functions";


$primary: #ede3ab;
$danger: darken( $primary, 30% );
$secondary:darken( $primary, 60% );
$success: lighten( $primary, 60% );
$warning: lighten( $primary, 30% );
$info: darken( $primary, 30% );

$body-bg: lighten( $primary, 80% );
$body-color: darken($primary, 60% );
$body-form-placeholder-color: $secondary;


$dark:darken($primary, 40% );

$col1:darken( $primary, 25% );
$col2:darken( $primary, 40% );
$col3:darken( $primary, 60% );
$col4:darken( $primary, 70% );
$col5:darken( $primary, 90% );

$search_col1:#b7449a;
$search_col2:#2d6928;
$search_col3:#131d25;
$search_col4:#00a9d1;
$search_col5:#f9c302;
$search_col6:#f36492;
$search_col7:#e6083a;
$search_col8:#8a422f;
$search_col9:#bbc1c5;
$search_col10:#fd4b25;
$search_col11:#f9fdef;
$search_col12:#8f3046;
$search_col13:#f3c685;
$search_col14:#174559;
$search_col15:#700e0f;
$search_col16:#c287a7;
$search_col17:#81b2a6;
$search_col18:#121235;
$search_col19:#d05d6a;
$search_col20:#a46e3f;
$search_col21:#03444a;
$search_col22:#90abc1;
$search_col23:#d1715b;
$search_col24:#8b9bb1;
$search_col25:#bf9e96;
$search_col26:#dd008c;
$search_col27:#97f79c;
$search_col28:#814458;

$input-font-size:1rem;

$border_col:$primary;

.search_color1 {
  background-color: $search_col1 !important;
}

.search_color2 {
  background-color: $search_col2 !important;
}

.search_color3 {
  background-color: $search_col3 !important;
}

.search_color4 {
  background-color: $search_col4 !important;
}

.search_color5 {
  background-color: $search_col5 !important;
}

.search_color6 {
  background-color: $search_col6 !important;
}

.search_color7 {
  background-color: $search_col7 !important;
}

.search_color8 {
  background-color: $search_col8 !important;
}

.search_color9 {
  background-color: $search_col9 !important;
}

.search_color10 {
  background-color: $search_col10 !important;
}

.search_color11 {
  background-color: $search_col11 !important;
}

.search_color12 {
  background-color: $search_col12 !important;
}

.search_color13 {
  background-color: $search_col13 !important;
}

.search_color14 {
  background-color: $search_col14 !important;
}

.search_color15 {
  background-color: $search_col15 !important;
}

.search_color16 {
  background-color: $search_col16 !important;
}

.search_color17 {
  background-color: $search_col17 !important;
}

.search_color18 {
  background-color: $search_col18 !important;
}

.search_color19 {
  background-color: $search_col19 !important;
}

.search_color20 {
  background-color: $search_col20 !important;
}

.search_color21 {
  background-color: $search_col21 !important;
}

.search_color22 {
  background-color: $search_col22 !important;
}

.search_color23 {
  background-color: $search_col23 !important;
}

.search_color24 {
  background-color: $search_col24 !important;
}

.search_color25 {
  background-color: $search_col25 !important;
}

.search_color26 {
  background-color: $search_col26 !important;
}

.search_color27 {
  background-color: $search_col27 !important;
}

.search_color28 {
  background-color: $search_col28 !important;
}

.carousel {
top:-5.8em;
}
.cccard-title
{
position:absolute;
bottom:3rem;
background:#fff;
display:none;
}

.card-img-overlay {
  pposition: absolute;
  top: inherit !important;
  rright: 0;
  bbottom: 0rem;
  lleft: 0;
  ppadding: 1rem;
  bborder-radius: calc(.25rem - 1px);
}

.card-body 
{
display:none;

}


.ccccard-body:hover 
{
display:block;
background: #f0f;
}

.icode
{
pposition:aabsolute;
bbottom:1rem;
bbackground:#fff;
dddisplay:none;
}

.ccccard:hover .cccard-title
{
display:block;
}

.cccard:hover .icccode
{
display:block;
}


.btn-lg 
{
  border: 2px solid #814458;
 }

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
    "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info
);



$navbar-light-brand-color: $danger!default;
$navbar-light-brand-hover-color: #fff;

$navbar-light-color:       rgba($body-color, .9);
$navbar-light-hover-color:          rgba($body-color, .4);
$navbar-light-active-color:         rgba($body-color, .9);

$nav-link-font-weight:600;
$navbar-padding-y:0;


$form-range-track-bg:$danger;

$pagination-padding-y:0;

.list-group-item {
  position: relative;
  display: block;
  padding: 0.125rem .5rem;
  color: $body-color;
  }



$font-family-sans-serif: Cormorant Garamond, Muli, "Segoe UI", Roboto, "Helvetica Neue",system-ui, -apple-system, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1.0rem;
$carousel-indicator-width:15px;

$input-bg:$success;

// Required
@import "bootstrap";

.icode
{
    font-family: Arial, Helvetica, sans-serif;
	font-size:0.90rem;
    font-weight: 600;
    ccolor: $danger;
}

.fab
{
font-size: 1.5rem;
}


header
{
height:5.8em;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 10px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
            transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 10px; }
  .nice-select.open:after {
    -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
        -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
            transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

 /* The sidebar menu */
.sidebar {
  height: calc(100vh - 13rem); /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 9.5rem;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 3rem; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  scrollbar-width: thin;
}

/* The sidebar links */
.sidebar a {
  text-decoration: none;
  color: #818181;
  
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  line-height:3rem;
  
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar a {font-size: 18px;}
} 


.map-container-6{
overflow:hidden;
padding-bottom:56.25%;
position:relative;
height:0;
}
.map-container-6 iframe{
left:0;
top:0;
height:100%;
width:100%;
position:absolute;
}

.fadein_title {
	position: absolute;
       font-size:1.5rem;
       font-weight:800;
        bottom:.25rem;
        left:.25rem;
        texxt-shadow: 2px 2px #ababab;
        color: $dark;
		background:rgba($light, .8);
		padding:.05rem;
     }

.fadein_title::after {
	position: absolute;
	content: "";
	width: 0%;
	height: 4px;
	left: 50%;
	bottom: -10px;
        background-color: darken($light,60%);
	transition: all 0.4s ease-in-out;
}

.image:hover .fadein_title::after {
	width: 100%;
	left: 0;
}

.image1:hover .fadein_title::after {
	width: 100%;
	left: 0;
}

.image2:hover .fadein_title::after {
	width: 100%;
	left: 0;
}


.single_footer_part h2 {
  background: $primary;
  padding: 4px;
  border-radius: 15px;
   }
.grey_box {
  background: #DBCDD9;
  margin: 5px;
  border-radius: 15px;
  padding: 5px;
}

.social_icon
{
font-size:1.40 em;
}
.social_icon a
{
color:#fff;
}




@media (max-width: 991px) {
  .footer_part .single_footer_part .social_icon {
    margin-top: 15px;
  }
}

.footer_part .single_footer_part .social_icon li a {
  margin-left: 0;
  margin-right: 25px;
  padding: 10px;
}

.form-range {
  width: 100%;
  padding: 10px 0 0 0; 
  }


.pjustify
{
text-align: justify; text-justify: inter-word;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-style: italic;
  font-size:1.2rem;
}

.fab,.far {
width:35px;
height:35px;
text-align:center;
margin:2px;
padding:7px 0 0 0;
   }

.fab:hover,.fas:hover,.far:hover
{
color:#fff;
}
.fa-facebook-f
{
background:#4c83da;
border-radius: 20rem;

}

.fa-facebook-f:hover
{
background:#115dd6;
}

.fa-youtube
{
background:#f55d56;
}
.fa-youtube:hover
{
background:#f7271e;
}
.fa-instagram-square
{
background:#4c83da;
}

.fa-instagram-square:hover
{
background:#115dd6;
}

.my_fb 
{
  display: block;
  text-indent: -9999px;
  width: 25px;
  height: 50px;
  background: url(../../img/fb.svg);
  background-size: 25px 50px;
}
.my_yt
{
  display: block;
  text-indent: -9999px;
  width: 90px;
  height: 20px;
   margin-top:15px;
  background: url(../../img/yt.svg);
  background-size: 90px 20px;
}
.my_ig
{
  display: block;
  text-indent: -9999px;
  width: 25px;
  height: 50px;
  background: url(../../img/ig.svg);
  background-size: 25px 50px;
}
.my_cart
{
  display: inline-block;
  text-indent: -9999px;
  width: 25px;
  height: 50px;
  background: url(../../img/cart.svg);
  background-size: 25px 50px;
}
.my_yt_button{
  display: block;
  text-indent: -9999px;
  width: 50px;
  height: 50px;
  background: url(../../img/yt_button.svg);
  background-size: 50px 50px;
}
.my_wa {
  display: block;
  text-indent: -9999px;
  width: 35px;
  height: 45px;
    margin-bottom: -.6em !important; 
  background: url(../../img/wa.svg);
    background-size: auto;
  background-size: 35px 45px;
}


#cart_item
{
position:relative;
left:-1.25em;
bottom: 1.5em;
display:inline-block;
text-align:center;
background:#f00;
border-radius:50%;
font-size:1em;
width:1.25em;
height:1.25em;
line-height:1em;
}
.single_footer_part > h2 {font-size:large;font-family:inherit;}
.form-group
{
margin:5px 0;
}
label
{
display:block;
}

h1,h2,h3 {font-size:xx-large;font-family: 'Cormorant Garamond', serif;}
h2 {font-weight:600;}
h5 {font-weight:600;font-size:1.10em;}
	.priya-carousal {height:150rem;}
.priya-carousal .carousel-item {
  height: 150rem;
}
p.decorated {font-family: 'Cormorant Garamond', serif;font-size:xx-large;}

.priya-carousal  .carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 300px;
}
	
.decorativeh5
{
position:absolute;
top:-200px;
font-size:xx-large;
text-transform: capitalize;
font-weight: 700;
margin-bottom: 1.5em;
text-shadow: 2px 2px black;
color:#fff; font-family: 'Cormorant Garamond', serif;
}	

.priya-carousal  .carousel-item .carousel-caption> h5 {
position:absolute;
top:-200px;
bbackground:rgba(224, 172, 90, 0.5);
font-size:xx-large;
text-transform: capitalize;
font-weight: 700;
margin-bottom: 1.5em;
text-shadow: 2px 2px red;
color:#fff; font-family: 'Cormorant Garamond', serif;
}
.priya-carousal   .carousel-item .carousel-caption .btn
{
position:absolute;
bottom:0px;
left:0px;
}


.priya-carousal  .carousel-item .carousel-caption
{
left:1%;
}
	/* Declare heights because of positioning of img element */
.carousel-item {
  height: 26rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 26rem;
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.bestsellers
{
margin-top:2em;
margin-bottom:2em;
}

.trending
{
margin-top:2em;
margin-bottom:2em;
} 

.newarrivals
{
margin-top:2em;
margin-bottom:2em;
}



.section
{
padding:2em 0;
}
/*************** subscribe now **********************/
.subscribe_area {
  background-image: url("https://www.priyasilkcollection.com/img/banner/footer_banner.jpg");
  background-position: bottom 0px left 0px;
  background-repeat: no-repeat;
  background-size: cover;
}

.subscribe_area h5 {
  font-size: 14px;
  font-weight: 400;
  color: #ff3368;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.subscribe_area h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin: 35px 0 67px;
  color:#ffb405fc;
}

@media (max-width: 991px) {
  .subscribe_area h2 {
    font-size: 30px;
    line-height: 35px;
    margin: 15px 0 30px;
  }
}

.subscribe_area .input-group input {
  height: 60px;
  padding-left: 25px;
  border: 0px solid transparent;
  background-color: #fff;
}

.subscribe_area .input-group input::placeholder {
  text-transform: capitalize;
}

.subscribe_area .input-group .input-group-text {
  height: 60px;
  width: auto;
  line-height: 60px;
  padding: 0px 45px;
  border: 0px solid transparent;
  text-transform: none;
  text-decoration:none;
  border-radius: 5px;
  margin-left: 10px;
}

@media (max-width: 576px) {
   .subscribe_area .input-group > .custom-file, .subscribe_area .input-group > .custom-select, .subscribe_area .input-group > .form-control, .subscribe_area .input-group > .form-control-plaintext {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: 0;
  }
   .subscribe_area .input-group {
    text-align: center;
    justify-content: center;
  }
   .subscribe_area .input-group-text {
    margin-top: 30px;
    text-align: center;
    margin-left: 0;
  }
}

.footer_part {
  padding: 1rem 0px .5rem;
}

@media (max-width: 576px) {
  .footer_part {
    padding: 70px 0px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_part {
    padding: 70px 0px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_part {
    padding: 70px 0px 25px;
  }
}

.footer_part img {
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

.footer_part .single_footer_part h4 {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 500;
}

@media (max-width: 576px) {
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer_part .single_footer_part h4 {
    font-size: 19px;
  }
}

.footer_part .single_footer_part p {
  margin-bottom: 10px;
}

.footer_part .single_footer_part .list-unstyled li a {
  color: #000;
  mmargin-bottom: 0.25em;
  display: inline-block;
   text-decoration:none;
}

.footer_part .single_footer_part .list-unstyled li a:hover {
  color: #ff3368 !important;
}

.footer_part .single_footer_part .mail_part {
  position: relative;
}

.footer_part .single_footer_part .mail_part input {
  padding: 20px 15px;
  font-size: 13px;
  border-radius: 5px;
  border: 0px solid transparent;
  margin-top: 20px;
  width: 80%;
  background-color: #f9f9fe;
}

.footer_part .single_footer_part .mail_part .email_icon {
  position: absolute;
  right: 0px;
  top: 30px;
  background-color: #ff3368;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  line-height: 10px;
  border: 2px solid #ff3368;
}

.footer_part hr {
  background-color: #cad7dc;
  margin-top: 50px;
  margin-bottom: 25px;
}

.footer_part .footer_icon {
  text-align: right;
}

.footer_part .footer_icon li {
  display: inline-block;
}

.footer_part .footer_icon li a {
  font-size: 14px;
  color: #7f7f7f;
  padding: 5px 11px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer_part .footer_icon li a:hover {
  background-color: #ff3368;
  box-shadow: -1.717px 8.835px 29.76px 2.24px rgba(255, 51, 104, 0.18);
  color: #fff !important;
}

.footer_part .footer_icon li a i:hover {
  color: #fff !important;
}

@media (max-width: 576px) {
  .footer_part .footer_icon li a {
    margin-left: 0px;
    margin-right: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_part .footer_icon li a {
    margin-left: 0px;
    margin-right: 25px;
  }
}

.footer_part .footer_icon li a :hover {
  color: #ff3368;
}

@media (max-width: 576px) {
  .footer_part .footer_icon {
    float: left;
    margin-top: 15px;
  }
}

.footer_part .copyright_text p {
  color: $col1;
  text-align:center;
}

.footer_part .copyright_text span {
  color: $col2;
  font-size: 14px;
}

@media (max-width: 991px) {
  .footer_part .copyright_text {
    text-align: center !important;
  }
}

.footer_part .copyright_text p a {
  color: $col1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer_part .copyright_text p a:hover {
  color: $col3;
}

.footer_part .info {
  color: $col5 !important;
}

.footer_part .copyright_part {
  margin-top: 45px;
}

@media (max-width: 991px) {
  .footer_part .footer_icon {
    text-align: center;
    float: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_part .justify-content-around {
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_part .justify-content-around {
    justify-content: flex-start !important;
  }
}


#footer_block
{
position:relative;
background-color: $primary;
}

#footer_my
{
position:absolute;
top:8rem;
right:14rem;
}


@media only screen and  (max-width: 992px)
{
#footer_my
{
right:4rem;
}
}


@media only screen and  (max-width: 992px)
{
#footer_my
{
top:12rem;
}
}

@media only screen and  (max-width: 768px)
{
#footer_my
{
right:2rem;
}
}


@media only screen and  (max-width: 768px)
{
#footer_my
{
top:20rem;
}
}

@media only screen and  (max-width: 576px)
{
#footer_my
{
right:2rem;
}
}


@media only screen and  (max-width: 576px)
{
#footer_my
{
top:31rem;
}
}



.image {
	position:relative;
    border: 2px solid #fff;
    width: 100%;
    overflow: hidden
}

.image img {
    width: 100%;
    transition: 1s ease-out
}

.image:hover img {
    transform: scale(1.1, 1.1);
    cursor: pointer
}

.image1 {
	position:relative;
    border: 2px solid $primary;
    width: 100%;
    overflow: hidden
}

.image1 img {
    width: 100%;
    transition: 1s ease-out
}

.image1:hover img {
    transform: scale(1.1, 1.1);
    cursor: pointer
}

.image2 {
	position:relative;
    border: 2px solid #fff;
    width: 100%;
    overflow: hidden
}

.image2 img {
    width: 100%;
    transition: 1s ease-out
}

.image2:hover img {
    transform: scale(1.1, 1.1);
    cursor: pointer
}



.card:hover .card-body {
display:block;
background: #ffffffb6;
}


.trans1:hover img {
    transform: scale(2, 1.1);
    cursor: pointer
}
.trans2:hover img {
    transform: scale(1.1, 1.1);
    cursor: pointer
}
.trans3:hover img {
    transform: scale(1.1, 1.1);
    cursor: pointer
}


.rounded {
  border-radius: 1.25rem; 
 } 

.svg {
  width: 300px;
  display: block;
  position: absolute;
}
.path {
  stroke-dasharray: 320;
  stroke-dashoffset: 0;
  animation: dash 2s infinite;
}
@keyframes dash {
  from {
    stroke-dashoffset: 320;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.wave {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index: 1;
}


.wwave {

  animation: move-waves 1s ease-in-out infinite;
}
.form-control
   {
   color: $body-form-placeholder-color;
   background-color:$body-bg;
   }
 
.form-control::placeholder {
   color: $body-form-placeholder-color;
   bbackground-color: $body-bg;
   opacity:1;
}

.form-select {
color:#000;
  background-color: #FFFBEC;

background-image: url("data:image/svg+xml,%3csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='Black' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
textarea.form-control
{
color:#000;
  background-color: #FFFBEC;

}
.feature_part h2 {
margin:1rem 0rem 1rem 0;
}

.subscribe_area h2, .subscribe_area .h2 {
  text-shadow: 2px 2px beige;
}

.subscribe_area .input-group .input-group-text {
  text-transform: none;
  text-decoration: none;
}


// Optional Bootstrap components here
@import "root";
@import "reboot";
@import "type";